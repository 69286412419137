<template>
  <div class="main row justify-center items-start bg-grey-7 q-gutter-xl" style>
    <q-card flat bordered style="width:210mm; " class="q-mt-xl">
      <q-card-section class="row justify-between">
        <q-btn
          dense
          outline
          color="primary"
          label="Download PDF"
          @click="download()"
        />
        <q-select
          dense
          outlined
          v-model="selSiswa"
          label="Siswa"
          :options="siswa"
          style="width:200px"
        />
      </q-card-section>
    </q-card>
    <div ref="cover">
      <q-card class="paper column justify-between">
        <q-card-section class="column items-center">
          <p style="font-size:18px">
            <strong>LAPORAN PENCAPAIAN HASIL BELAJAR PESERTA DIDIK</strong>
          </p>
          <p style="font-size:18px">
            <strong>MADRASAH 'ALIYAH SWASTA (MAS)</strong>
          </p>
          <p class="text-overline" style="font-size:14px">
            PERGURUAN ISLAM AR RISALAH
          </p>
        </q-card-section>
        <q-card-section
          class="column justify-evenly text-center"
          style="height:225mm"
        >
          <div><img src="../../public/img/logo.png" style="height:75mm" /></div>
          <div v-if="selSiswa">
            <p class="q-mb-xs" style="font-size:14px">
              <strong>Nama Peserta Didik:</strong>
            </p>
            <p style="font-size:18px">
              <strong>{{ selSiswa.label }}</strong>
            </p>
            <p class="q-mb-xs" style="font-size:14px">
              <strong>NIS/NISN:</strong>
            </p>
            <p v-if="selSiswa.nisn != null" style="font-size:18px">
              <strong>{{ selSiswa.nisn }}</strong>
            </p>
            <p v-else style="font-size:18px">
              <strong>N/A</strong>
            </p>
          </div>
        </q-card-section>
        <q-card-section class="text-center">
          <p style="font-size:18px">
            <strong>KEMENTRIAN AGAMA</strong>
          </p>
          <p style="font-size:18px">
            <strong>REPUBLIK INDONESIA</strong>
          </p>
        </q-card-section>
      </q-card>
    </div>
    <div ref="alamat">
      <q-card class="paper column ">
        <q-card-section class="text-center">
          <p style="font-size:18px">
            <strong>LAPORAN PENCAPAIAN PESERTA DIDIK</strong>
          </p>
          <p style="font-size:18px">
            <strong>MADRASAH 'ALIYAH</strong>
          </p>
          <p style="font-size:18px">
            <strong>MA</strong>
          </p>
        </q-card-section>
        <q-card-section class="q-px-none">
          <q-markup-table dense flat>
            <tr>
              <td>Nama Sekolah</td>
              <td>:</td>
              <td style="width:75%">
                <q-input
                  dense
                  readonly
                  value="Madrasah 'Aliyah Swasta (MAS) Perguruan Islam Ar-Risalah"
                ></q-input>
              </td>
            </tr>
            <tr>
              <td>NPSN</td>
              <td>:</td>
              <td style="width:75%">
                <q-input dense readonly value="10263742"></q-input>
              </td>
            </tr>
            <tr>
              <td>NIS/NSM</td>
              <td>:</td>
              <td style="width:75%">
                <q-input dense readonly value="305/131213710005"></q-input>
              </td>
            </tr>
            <tr>
              <td>Alamat Sekolah</td>
              <td>:</td>
              <td style="width:75%">
                <q-input
                  dense
                  readonly
                  value="Kampus Ar Risalah Air Dingin RT .01 RW .IX"
                ></q-input>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td class="row justify-between" style="width:75%">
                <div class="row justify-between items-center" style="width:35%">
                  <span>Kode Pos</span>
                  <q-input
                    dense
                    readonly
                    value="25174"
                    style="width:65%"
                  ></q-input>
                </div>
                <div class="row justify-between items-center" style="width:60%">
                  <span>Telpon</span>
                  <q-input
                    dense
                    readonly
                    value="0751-7877114, 9824888"
                  ></q-input>
                </div>
              </td>
            </tr>
            <tr>
              <td>Desa/Kelurahan</td>
              <td>:</td>
              <td style="width:75%">
                <q-input dense readonly value="Balai Gadang"></q-input>
              </td>
            </tr>
            <tr>
              <td>Kecamatan</td>
              <td>:</td>
              <td style="width:75%">
                <q-input dense readonly value="Koto Tengah"></q-input>
              </td>
            </tr>
            <tr>
              <td>Kota/Kabupaten</td>
              <td>:</td>
              <td style="width:75%">
                <q-input dense readonly value="Padang"></q-input>
              </td>
            </tr>
            <tr>
              <td>Provinsi</td>
              <td>:</td>
              <td style="width:75%">
                <q-input dense readonly value="Sumatera Barat"></q-input>
              </td>
            </tr>
            <tr>
              <td>Website</td>
              <td>:</td>
              <td style="width:75%">
                <q-input
                  dense
                  readonly
                  value="http://www.arrisalah-sumber.sch.id"
                ></q-input>
              </td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>:</td>
              <td style="width:75%">
                <q-input
                  dense
                  readonly
                  value="mas-arrisalah@yahoo.co.id"
                ></q-input>
              </td>
            </tr>
          </q-markup-table>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import moment from "moment";
import { jsPDF } from "jspdf";

export default {
  data() {
    return {
      siswa: [],
      selSiswa: null
    };
  },
  async created() {
    this.getSiswa();
  },
  methods: {
    async download() {
      this.$q.loading.show();
      const doc = new jsPDF("p", "mm", "a4");

      let data_cover = await html2canvas(this.$refs.cover, { scale: 2 });
      const cover = data_cover.toDataURL("image/png");
      let data_alamat = await html2canvas(this.$refs.alamat, { scale: 2 });
      const alamat = data_alamat.toDataURL("image/png");

      doc.addImage(cover, "JPEG", 0, 0, 210, 297, "alias1", "MEDIUM");
      doc.addPage();
      doc.addImage(alamat, "JPEG", 0, 0, 210, 297, "alias2", "MEDIUM");
      doc.save(`Sampul ${this.selSiswa.label}.pdf`);
      this.$q.loading.hide();
    },
    async getSiswa() {
      let resp = await this.$http.get(`/hasil/sampul/getsiswa/5`);
      this.siswa = resp.data;
      this.selSiswa = resp.data[0];
    }
  }
};
</script>

<style lang="scss">
.text-predikat {
  font-size: 36px !important;
}
.text-lebih-kecil {
  font-size: 10px !important;
}
.text-kecil {
  font-size: 12px !important;
}
.text-besar {
  font-size: 14px !important;
}
.paper {
  width: 210mm !important;
  height: 330mm !important;
  // height: 297mm;
  padding: 1cm 1.9cm 1cm 1.9cm;
}
</style>
